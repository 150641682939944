import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Table from "../components/Table/table"
import StartOverButton from "../components/StartOverButton/StartOverButton"

import "./index.scss"

import { PROGRAM_TABLE } from "../types/types"

export default class ProgramTable extends React.Component<{}> {
  render() {
    return (
      <Layout>
        <SEO title="Program Table" />
        <StartOverButton />
        <Table tableType={PROGRAM_TABLE} />
      </Layout>
    )
  }
}
